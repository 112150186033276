import http from "../utils/http";
// get请求
export default {
  companyProfileselect(params) {
    return http.get(`/officialWeb_api/api/companyProfile/select`, params);
  },
  selectNews(params) {
    return http.get(`/officialWeb_api/api/companyNews/selectNews`, params);
  },

  selectTimeline(params) {
    return http.get(
      `/officialWeb_api/api/companyTimeline/selectTimeline`,
      params
    );
  },
  addCoopera(params) {
    return http.post(
      `/officialWeb_api/api/companyCooperate/addCoopera`,
      params
    );
  },
  selectAll(params) {
    return http.get(`/officialWeb_api/api/companyPositions/selectAll`, params);
  },
  companyCoreTechnologySelectAll(params) {
    return http.get(
      `/officialWeb_api/api/companyCoreTechnology/selectAll`,
      params
    );
  },
  selectByProject(params) {
    return http.get(
      `/officialWeb_api/api/companyProject/selectByProject`,
      params
    );
  },
  selectLink(params) {
    return http.get(
      `/officialWeb_api/api/companyEmbedLinks/selectLink`,
      params
    );
  },
};
