import CryptoJS from "crypto-js";

// Base64 解码函数
function decodeBase64(base64String) {
  return CryptoJS.enc.Base64.parse(base64String).toString(CryptoJS.enc.Utf8);
}

// AES 解密函数
export function decryptAES(encryptedData, secretKey, initVector) {
  try {
    // Base64 解码
    const key = CryptoJS.enc.Base64.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(initVector);
    const encryptedHex = CryptoJS.enc.Base64.parse(encryptedData).toString(
      CryptoJS.enc.Hex
    );

    // 进行解密
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(encryptedHex) },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    // 返回解密后的字符串
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
}
