import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css";
import footer from "./components/footer.vue";
// import 'amfe-flexible';
Vue.use(ElementUI);
import api from "@/api/api.js";
Vue.prototype.$api = api;
import VueParticles from "vue-particles";
Vue.use(VueParticles);

// Vue.prototype.$imageBaseUrl = "https://www.hfjscn.com/officialWeb_api";
// Vue.prototype.$imageBaseUrl = "http://192.168.200.207:8888/officialWeb_api";

Vue.mixin({
  data() {
    return {
      imageBaseUrl: "https://www.hfjscn.com/officialWeb_api"   //生产环境,
      // imageBaseUrl: "http://192.168.200.207:8888/officialWeb_api", //测试环境
    };
  },
});

Vue.component("footer-bar", footer);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
